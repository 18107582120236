import queryString from 'query-string'
import Cookies from 'universal-cookie'

import * as apiApply from '../api/apply'

const initState = {
  progress: 0,
  type: 'medicare',
  step: 'zip',
  highestStep: 'zip',
  data: {},
  overage: true,
  api: {
    pending: false,
    error: false,
    message: '',
  },
  src: '',
  campaign: '',
  userCity: '',
  userState: '',
  newApply: false,
  bypassZip: false,
  includeExisting: false,
  includeAddress: false,
  otherInsuranceDefault: true,
  specialFlow: false,
  homeOrigin: null,
  Sub_ID: '',
  Pub_ID: '',
  skipQuestions: false,
}

const flows = {
  medicare: {
    0: {
      key: 'zip',
      progress: 0,
    },
    1: {
      key: 'birth',
      progress: 28,
    },
    2: {
      key: 'existing',
      progress: 55,
    },
    3: {
      key: 'gender',
      progress: 80,
    },
    4: {
      key: 'pii',
      progress: 100,
    },
    5: {
      key: 'final',
      progress: 100,
    },
  },
  medicareAlt: {
    0: {
      key: 'dobmonth',
      progress: 16,
    },
    1: {
      key: 'dobday',
      progress: 32,
    },
    2: {
      key: 'dobyear',
      progress: 48,
    },
    3: {
      key: 'existing',
      progress: 64,
    },
    4: {
      key: 'gender',
      progress: 80,
    },
    5: {
      key: 'address',
      progress: 96,
    },
    6: {
      key: 'pii',
      progress: 100,
    },
    7: {
      key: 'final',
      progress: 100,
    },
  },
  medicareAltOne: {
    0: {
      key: 'existing',
      progress: 0,
    },
    1: {
      key: 'dobmonthNew',
      progress: 25,
    },
    2: {
      key: 'dobdayNew',
      progress: 50,
    },
    3: {
      key: 'dobyearNew',
      progress: 80,
    },
    4: {
      key: 'pii',
      progress: 100,
    },
    5: {
      key: 'final',
      progress: 100,
    },
  },
  health: {
    0: {
      key: 'zip',
      progress: 0,
    },
    1: {
      key: 'birth',
      progress: 14,
    },
    2: {
      key: 'disability',
      progress: 29,
    },
    3: {
      key: 'gender',
      progress: 24,
    },
    4: {
      key: 'income',
      progress: 39,
    },
    5: {
      key: 'household',
      progress: 53,
    },
    6: {
      key: 'height',
      progress: 68,
    },
    7: {
      key: 'weight',
      progress: 83,
    },
    8: {
      key: 'tobacco',
      progress: 96,
    },
    9: {
      key: 'pii',
      progress: 100,
    },
    10: {
      key: 'final',
      progress: 100,
    },
  },
}

// Action names
const UPDATE_PROGRESS = 'UPDATE_PROGRESS'
const UPDATE_STEP = 'UPDATE_STEP'
const UPDATE_HIGHEST_STEP = 'UPDATE_HIGHEST_STEP'
const UPDATE_TYPE = 'UPDATE_TYPE'
const UPDATE_FORM_DATA = 'UPDATE_FORM_DATA'
const SUBMIT_APPLICATION_PENDING = 'SUBMIT_APPLICATION_PENDING'
const SUBMIT_APPLICATION_SUCCESS = 'SUBMIT_APPLICATION_SUCCESS'
const SUBMIT_APPLICATION_FAILURE = 'SUBMIT_APPLICATION_FAILURE'
const UPDATE_SRC = 'UPDATE_SRC'
const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
const UPDATE_USER_CITY = 'UPDATE_USER_CITY'
const TOGGLE_APPLY = 'TOGGLE_APPLY'
const TOGGLE_BYPASS_ZIP = 'TOGGLE_BYPASS_ZIP'
const TOGGLE_INCLUDE_ADDRESS = 'TOGGLE_INCLUDE_ADDRESS'
const TOGGLE_INCLUDE_EXISTING = 'TOGGLE_INCLUDE_EXISTING'
const TOGGLE_FIELD_VALUE = 'TOGGLE_FIELD_VALUE'

// Action Handlers
export const checkUrlSrc = (search) => (dispatch, getState) => {
  const parsed = queryString.parse(search)
  if (parsed.SRC && getState().captive.src === '') {
    dispatch({ type: UPDATE_SRC, payload: parsed.SRC })
  }

  if (parsed.Sub_ID && getState().captive.Sub_ID === '') {
    dispatch({
      type: TOGGLE_FIELD_VALUE,
      payload: {
        field: 'Sub_ID',
        value: parsed.Sub_ID,
      },
    })
  }

  if (parsed.Pub_ID && getState().captive.Pub_ID === '') {
    dispatch({
      type: TOGGLE_FIELD_VALUE,
      payload: {
        field: 'Pub_ID',
        value: parsed.Pub_ID,
      },
    })
  }
}
export const checkUrlCampaign = (search) => (dispatch, getState) => {
  const parsed = queryString.parse(search)
  if (parsed.utm_campaign && getState().captive.campaign === '') {
    dispatch({ type: UPDATE_CAMPAIGN, payload: parsed.utm_campaign })
  }
}
export const checkPrePopulatedData = (search, setSkipQuestions = false) => (
  dispatch,
  getState,
) => {
  const parsed = queryString.parse(search)

  console.log(getState())

  if (setSkipQuestions) {
    dispatch({
      type: TOGGLE_FIELD_VALUE,
      payload: {
        field: 'skipQuestions',
        value: true,
      },
    })
  }

  const skipQuestions = setSkipQuestions || getState().captive.skipQuestions

  var prePopulatedMapping = {
    type: 'type',
    first: 'first',
    last: 'last',
    gender: 'gender',
    dob: 'dob',
    address: 'address',
    zip: 'zip',
    phone: 'phone',
    email: 'email',
    other: 'other',
    short: 'short',
    existing: 'existing',
    highestStep: 'highestStep',
  }
  let dataKey

  for (dataKey in prePopulatedMapping) {
    if (dataKey in parsed) {
      let dataMap = prePopulatedMapping[dataKey]
      let paramVal = parsed[dataKey]
      if (!paramVal.includes('{')) {
        if (dataKey === 'dob') {
          const dob = new Date(paramVal)
          let now = new Date()
          let diffMs = now.getTime() - dob.getTime()
          let diffYears = diffMs / (365 * 1000 * 60 * 60 * 24)
          let newType = initState.type
          let overAge = initState.overage
          let overFiftyFive = false

          if (diffYears <= 64.5) {
            newType = 'health'
            overAge = false
          }
          if (diffYears > 55) {
            overFiftyFive = true
          }
          dispatch({ type: UPDATE_TYPE, payload: newType })

          dispatch({
            type: UPDATE_FORM_DATA,
            payload: {
              day: dob.getDate(),
              month: dob.getMonth() + 1,
              year: dob.getFullYear(),
              overage: overAge,
              age: diffYears,
              overfiftyfive: overFiftyFive,
            },
          })
        } else if (dataKey === 'other') {
          dispatch({
            type: UPDATE_FORM_DATA,
            payload: { other: paramVal === 'false' ? false : true },
          })
        } else if (
          dataKey === 'type' &&
          (paramVal === 'health' ||
            paramVal === 'medicare' ||
            paramVal === 'medicareAlt' ||
            paramVal === 'medicareAltOne')
        ) {
          dispatch({ type: UPDATE_TYPE, payload: paramVal })
        } else {
          dispatch({ type: UPDATE_FORM_DATA, payload: { [dataMap]: paramVal } })
        }
      }
    }
  }

  if (parsed.bypassZip) {
    dispatch({
      type: TOGGLE_BYPASS_ZIP,
      payload: parsed.bypassZip === 'false' || false ? false : true,
    })
  }

  // Special case for handling zip auto advance
  if (parsed.zip && !parsed.zip.includes('{') && parsed.a) {
    dispatch({ type: UPDATE_HIGHEST_STEP, payload: 'birth' })
    dispatch({ type: UPDATE_STEP, payload: 'birth' })
    dispatch({ type: TOGGLE_APPLY, payload: true })
  }

  if (parsed.step) {
    dispatch({ type: UPDATE_STEP, payload: parsed.step })
  }

  if (parsed.highestStep) {
    dispatch({ type: UPDATE_HIGHEST_STEP, payload: parsed.highestStep })
  }

  if (parsed.includeAddress) {
    dispatch({
      type: TOGGLE_INCLUDE_ADDRESS,
      payload: parsed.includeAddress === 'false' || false ? false : true,
    })
  }

  if (parsed.toggleApply) {
    dispatch({
      type: TOGGLE_APPLY,
      payload: parsed.toggleApply === 'false' || false ? false : true,
    })
  }

  if (parsed.otherInsuranceDefault) {
    dispatch({
      type: TOGGLE_FIELD_VALUE,
      payload: {
        field: 'otherInsuranceDefault',
        value: parsed.otherInsuranceDefault === 'false' || false ? false : true,
      },
    })
  }

  if (parsed.specialFlow) {
    dispatch({
      type: TOGGLE_FIELD_VALUE,
      payload: {
        field: 'specialFlow',
        value: parsed.specialFlow === 'false' || false ? false : true,
      },
    })
  }

  if (skipQuestions) {
    if (
      parsed.zip &&
      !parsed.zip.includes('{') &&
      parsed.address &&
      !parsed.address.includes('{')
    ) {
      dispatch({
        type: TOGGLE_FIELD_VALUE,
        payload: {
          field: 'skipAddress',
          value: true,
        },
      })
    }

    if (parsed.gender && !parsed.gender.includes('{')) {
      dispatch({
        type: TOGGLE_FIELD_VALUE,
        payload: {
          field: 'skipGender',
          value: true,
        },
      })
    }

    if (parsed.dob && !parsed.dob.includes('{')) {
      dispatch({
        type: TOGGLE_FIELD_VALUE,
        payload: {
          field: 'skipDOB',
          value: true,
        },
      })
    }
  }
}

export const checkUserCity = (force) => (dispatch, getState) => {
  const cookies = new Cookies()
  const cookieToken = 'cm_loc'
  const cookieVal = cookies.get(cookieToken)

  if (typeof cookieVal !== 'undefined' && cookieVal.state && cookieVal.city) {
    dispatch({
      type: UPDATE_USER_CITY,
      payload: {
        city: cookieVal.city,
        state: cookieVal.state,
      },
    })
  } else if (getState().captive.userCity === '' || typeof cookieVal === 'undefined') {
    apiApply.getCityState().then((res) => {
      dispatch({
        type: UPDATE_USER_CITY,
        payload: {
          city: res && res.city ? res.city : '',
          state: res && res.region ? res.region : '',
        },
      })
      cookies.set(cookieToken, { city: res.city, state: res.region })
    })
  }
}

export const submitZip = (zip) => (dispatch) => {
  dispatch({ type: UPDATE_FORM_DATA, payload: { zip: zip } })
  return true
}

export const submitDob = (year) => (dispatch, getState) => {
  if (Number.isInteger(year)) {
    year = year + ''
  }
  year = year.length === 2 ? '19' + year : year
  let month = getState().captive.data.month
  let day = getState().captive.data.day
  let now = new Date()
  let dob = new Date(year, month - 1, day)
  let diffMs = now.getTime() - dob.getTime()
  let diffYears = diffMs / (365 * 1000 * 60 * 60 * 24)
  let newType = getState().captive.type
  let overAge = initState.overage
  let overFiftyFive = false

  const oldType =
    newType !== 'health'
      ? newType
      : getState()?.captive?.oldType
      ? getState()?.captive?.oldType
      : 'medicare'

  if (diffYears <= 64.5) {
    newType = 'health'
    overAge = false
  }

  if (diffYears > 55) {
    overFiftyFive = true
  }
  dispatch({ type: UPDATE_TYPE, payload: newType })

  dispatch({
    type: UPDATE_FORM_DATA,
    payload: {
      year: year,
      overage: overAge,
      age: diffYears,
      overfiftyfive: overFiftyFive,
      oldType,
    },
  })
}

export const submitDisability = (disability, oldType = 'medicare') => (
  dispatch,
  getState,
) => {
  if (disability === 'Yes') {
    dispatch({ type: UPDATE_TYPE, payload: oldType })
  }

  dispatch({
    type: UPDATE_FORM_DATA,
    payload: {
      disability,
    },
  })
}

export const goToStep = (step, checkHighest = false) => (dispatch, getState) => {
  const nextObject = determineNextStep(getState().captive.type, step, false, true)
  dispatch({ type: UPDATE_PROGRESS, payload: nextObject.progress })
  dispatch({ type: UPDATE_STEP, payload: nextObject.key })
}

export const nextStep = (current, prev = false) => (dispatch, getState) => {
  let nextObject = determineNextStep(getState().captive.type, current, prev)

  // pre-pop bypass dob
  if (
    (nextObject.key === 'birth' ||
      nextObject.key === 'dobmonth' ||
      nextObject.key === 'dobday' ||
      nextObject.key === 'dobyear' ||
      nextObject.key === 'dobmonthNew' ||
      nextObject.key === 'dobdayNew' ||
      nextObject.key === 'dobyearNew') &&
    getState().captive.skipDOB &&
    !prev
  ) {
    if (nextObject.key === 'birth') {
      nextObject = determineNextStep(getState().captive.type, nextObject.key, prev)
    }
    if (nextObject.key === 'dobmonth') {
      nextObject = determineNextStep(getState().captive.type, 'dobyear', prev)
    }

    if (nextObject.key === 'dobmonthNew') {
      nextObject = determineNextStep(getState().captive.type, 'dobyearNew', prev)
    }
  }

  // check for bypass of zip
  if (
    nextObject.key === 'existing' &&
    getState().captive.bypassZip &&
    !getState().captive.includeExisting &&
    !prev
  ) {
    nextObject = determineNextStep(getState().captive.type, nextObject.key, prev)
  }

  // pre-pop bypass gender
  if (nextObject.key === 'gender' && getState().captive.skipGender && !prev) {
    nextObject = determineNextStep(getState().captive.type, nextObject.key, prev)
  }

  if (nextObject.key === 'existing' && getState().captive.skipExisting && !prev) {
    nextObject = determineNextStep(getState().captive.type, nextObject.key, prev)
  }

  // pre-pop bypass address
  if (nextObject.key === 'address' && getState().captive.skipAddress && !prev) {
    nextObject = determineNextStep(getState().captive.type, nextObject.key, prev)
  }

  // check for medicareAlt switch to health
  if (current === 'dobyear' && getState().captive.type === 'health' && !prev) {
    nextObject = determineNextStep(getState().captive.type, 'birth', prev)
    // Reset the address to show on PII for health alt
    dispatch({ type: TOGGLE_INCLUDE_ADDRESS, payload: false })
  }

  // check for medicareAltOne switch to health
  if (current === 'dobyearNew' && getState().captive.type === 'health' && !prev) {
    nextObject = determineNextStep(getState().captive.type, 'birth', prev)
    // Reset the address to show on PII for health alt
    dispatch({ type: TOGGLE_INCLUDE_ADDRESS, payload: false })
  }

  dispatch({ type: UPDATE_PROGRESS, payload: nextObject.progress })
  dispatch({ type: UPDATE_STEP, payload: nextObject.key })
  if (!prev) {
    dispatch({ type: UPDATE_HIGHEST_STEP, payload: nextObject.key })
  }

  return nextObject.key
}

export const determineNextStep = (
  type,
  currentKey,
  prev = false,
  returnMatch = false,
) => {
  let db = flows[type]

  for (var i = 0; i < Object.keys(db).length; i++) {
    if (db[i].key === currentKey) {
      let keyVal = prev ? i - 1 : i + 1
      keyVal = returnMatch ? i : keyVal
      return db[keyVal]
    }
  }

  return db[0]
}

export const submitData = (data) => (dispatch) => {
  dispatch({ type: UPDATE_FORM_DATA, payload: data })
}

export const preSubmitApplication = () => (dispatch) => {
  dispatch({ type: SUBMIT_APPLICATION_PENDING, payload: true })
}

export const submitApplication = () => (dispatch, getState) => {
  dispatch({ type: SUBMIT_APPLICATION_PENDING, payload: true })
  const { data, type, src } = getState().captive
  const defaultTypeData = { ...data, other: false }

  console.log({ defaultTypeData })

  return apiApply
    .submitApplication(type, defaultTypeData, src)
    .then((response) => {
      console.log(response)
      dispatch({
        type: SUBMIT_APPLICATION_SUCCESS,
        payload: response,
      })

      if (data.other) {
        // Handle additional life submission
        apiApply
          .submitApplication(type, data, src)
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            //Just bypass it
          })
      }

      return response
    })
    .catch((err) => {
      dispatch({
        type: SUBMIT_APPLICATION_FAILURE,
        payload:
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : err.message,
      })
      return Promise.reject(err)
    })
}

export const changeType = (type) => (dispatch) => {
  if (type in flows) {
    dispatch({ type: UPDATE_TYPE, payload: type })
  }
}
export const toggleBypassZip = (value) => (dispatch) => {
  dispatch({ type: TOGGLE_BYPASS_ZIP, payload: value })
}
export const toggleIncludeExisting = (value) => (dispatch) => {
  dispatch({ type: TOGGLE_INCLUDE_EXISTING, payload: value })
}

export const toggleIncludeAddress = (value) => (dispatch) => {
  dispatch({ type: TOGGLE_INCLUDE_ADDRESS, payload: value })
}
export const toggleApply = (value) => (dispatch) => {
  dispatch({ type: TOGGLE_APPLY, payload: value })
}
export const toggleField = (field, value) => (dispatch) => {
  dispatch({ type: TOGGLE_FIELD_VALUE, payload: { field, value } })
}

// Reducer
export default (state = initState, action) => {
  switch (action.type) {
    case UPDATE_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      }
    case UPDATE_STEP:
      return {
        ...state,
        step: action.payload,
      }
    case UPDATE_HIGHEST_STEP:
      return {
        ...state,
        highestStep: action.payload,
      }
    case UPDATE_TYPE:
      return {
        ...state,
        type: action.payload,
      }
    case UPDATE_FORM_DATA:
      const newData = { ...state.data, ...action.payload }
      return {
        ...state,
        data: newData,
      }
    case SUBMIT_APPLICATION_PENDING:
      return {
        ...state,
        api: {
          pending: action.payload,
          message: '',
          error: false,
        },
      }
    case SUBMIT_APPLICATION_SUCCESS:
      return {
        ...state,
        api: {
          pending: false,
          message: '',
          error: false,
        },
      }
    case SUBMIT_APPLICATION_FAILURE:
      return {
        ...state,
        api: {
          pending: false,
          message: action.payload,
          error: true,
        },
      }
    case UPDATE_SRC:
      return {
        ...state,
        src: action.payload,
      }
    case UPDATE_CAMPAIGN:
      return {
        ...state,
        campaign: action.payload,
      }
    case UPDATE_USER_CITY:
      return {
        ...state,
        userCity: action.payload.city,
        userState: action.payload.state,
      }
    case TOGGLE_APPLY:
      return {
        ...state,
        newApply: action.payload,
      }
    case TOGGLE_BYPASS_ZIP:
      return {
        ...state,
        bypassZip: action.payload,
      }
    case TOGGLE_INCLUDE_ADDRESS:
      return {
        ...state,
        includeAddress: action.payload,
      }
    case TOGGLE_INCLUDE_EXISTING:
      return {
        ...state,
        includeExisting: action.payload,
      }
    case TOGGLE_FIELD_VALUE:
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      }
    default:
      return state
  }
}
